import React, { Component } from "react";

import PropTypes from "prop-types";

import { compose } from 'redux'

import { connect } from 'react-redux'

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import { get, difference, } from "lodash";

import { withRouter, Link as RouterLink, } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  ButtonGroup,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineDot,
  Alert,
} from "@material-ui/lab";

import { withStyles, } from "@material-ui/core/styles";

import Image from "material-ui-image";

import { auth, firestoreConstants, } from "../../firebase";

import Countdown from "../Countdown";

import Loader from "../Loader";

import routes, {
  RANKS,
  SEASON_PREDICTIONS,
  STANDINGS,
  MY_ENTRY,
  WEEKLY_PREDICTIONS,
  SEASONS,
} from "../../data/routes";

import authentication from "../../services/authentication";

import {
  myUserEntry,
  rankings,
  seasonPredictions,
} from "../../data/season-constants";

import userEntries from "../../services/user-entries";

const imageHeight = "540px";
const mobileImageHeight = "240px";

const styles = (theme) => ({
  grid: {
    margin: 0,
    width: "100%",
  },
  listItem: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    fontWeight: 500
  },
  listItemText: {
    fontWeight: "inherit",
  },
});

class HomePage extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  isRankingComplete = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    const season = seasons === undefined ? null : seasons[seasonId];

    return userEntries.isStepComplete(rankings, myUserEntry, season);
  };

  isSeasonPredictionsComplete = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    const season = seasons === undefined ? null : seasons[seasonId];

    return userEntries.isStepComplete(seasonPredictions, myUserEntry, season);
  };

  isWeeklyPredictionsComplete = () => {
    const {
      myUserEntryAll,
      seasons,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    const myUserEntry = myUserEntryAll === undefined || myUserEntryAll.length == 0 ? null : myUserEntryAll[0];
    const season = seasons === undefined ? null : seasons[seasonId];

    if (!myUserEntry) {
      return false;
    }

    const {
      weeklyPredictions,
    } = myUserEntry;

    const {
      currentWeeklyPredictionsWeek,
      weeklyPredictionsQuestions = undefined,
    } = season;

    if (!weeklyPredictionsQuestions) {
      return true;
    }

    const questionsForWeek = weeklyPredictionsQuestions.byWeek[currentWeeklyPredictionsWeek];
    const questionIds = questionsForWeek.allIds;

    const weeklyPredictionsAnswers = get(weeklyPredictions, currentWeeklyPredictionsWeek);
    if (!weeklyPredictionsAnswers) {
      return false;
    }

    const answerIds = Object.keys(weeklyPredictionsAnswers).map(key => parseInt(key)).sort();
    return difference(questionIds, answerIds).length === 0;
  };

  isUserSetup = () => {
    const {
      user,
    } = this.props;

    return user && (user.firstName || user.lastName);
  };

  isLoaded = () => {
    const {
      seasons,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;

    if (this.props.user) {
      return isLoaded(
        seasons,
        myUserEntryAll,
        myUserEntryById,
      );
    } else {
      return isLoaded(
        seasons,
      );
    }
  }

  render() {
    const {
      classes,
      user,
      myUserEntryAll,
      seasons,
    } = this.props;

    // Functions
    const {
      onSignUpClick,
      onSignInClick,
      onSettingsClick,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const rankingsComplete = userEntries.isRankingComplete(myUserEntryAll, seasons);
    const seasonPredictionsComplete = this.isSeasonPredictionsComplete();
    const isUserComplete = this.isUserSetup();
    const season = seasons[seasonId];

    const {
      id,
      name,
      imageUrl,
      isSeasonPredictionsLocked,
      isRankingsLocked,
      isWeeklyPredictionsLocked,
      isSeasonOver,
      isScoringComplete = true,
      isBachelorette,
      currentWeeklyPredictionsWeek,
      nextEpisodeAirDate,
      nextEpisodeAirDateEnglish,
      secondEpisodeAirDate,
      secondEpisodeAirDateEnglish,
    } = season;

    const weeklyPredictionsComplete = isWeeklyPredictionsLocked ? true : this.isWeeklyPredictionsComplete();
    const baseUrl = routes[SEASONS].pathWithoutParam + seasonId;

    return (
      <Box>
        <Hidden mdUp>
          <Box
            alignItems="center"
            justify="center"
            className={classes.grid}
          >
              <Box style={{
                height: mobileImageHeight,
              }}>
                <Image
                  src={`/images/${imageUrl}`}
                  cover
                  disableTransition
                  imageStyle={{
                    height: mobileImageHeight,
                  }}
                  iconContainerStyle={{
                    height: mobileImageHeight,
                  }}
                  style={{
                    paddingTop: 0,
                  }}
                />
              </Box>
            <Box>
              <Paper>
                <Grid
                  container
                  spacing={7}
                  className={classes.grid}
                >
                  <Grid item xs={12}>
                    {user && !isWeeklyPredictionsLocked && (
                      <Box mb={8}>
                        <Typography variant="h3" gutterBottom>
                          Week {currentWeeklyPredictionsWeek} predictions are up!
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                          Fill them out before you watch <strong>Episode {currentWeeklyPredictionsWeek}</strong>, which airs on <strong>{nextEpisodeAirDateEnglish}</strong>:
                        </Typography>
                        <Alert
                          severity={weeklyPredictionsComplete ? "success" : "warning"}
                          action={
                            <Button
                              size="small"
                              color={weeklyPredictionsComplete ? "inherit" : "primary"}
                              variant={weeklyPredictionsComplete ? "outlined" : "contained"}
                              disableElevation
                              component={RouterLink}
                              to={`${baseUrl}${routes[WEEKLY_PREDICTIONS].path}`}
                            >
                              View Weekly Predictions
                            </Button>
                          }
                        >
                          {weeklyPredictionsComplete 
                            ? <strong>You're finished with your weekly predictions for week {currentWeeklyPredictionsWeek}!</strong>
                            : <strong>Make sure you fill out your weekly predictions!</strong>
                          }
                        </Alert>
                      </Box>
                    )}
                    {isSeasonOver ? (
                      <>
                        <Hidden smDown>
                          <Typography variant="h3" gutterBottom>
                            Thanks for joining us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                          </Typography>
                          {isScoringComplete && (
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box
                                  borderRadius={8}
                                  border={1}
                                >
                                  <ListItem
                                    button
                                    component={RouterLink}
                                    to={`${baseUrl}${routes[MY_ENTRY].path}`}
                                    className={classes.listItem}
                                  >
                                    <Box m={2} display="flex">
                                      <ListItemIcon>
                                        {routes[MY_ENTRY].icon}
                                      </ListItemIcon>
                                      <ListItemText primary={routes[MY_ENTRY].name} classes={{primary: classes.listItemText}} />
                                    </Box>
                                  </ListItem>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  borderRadius={8}
                                  border={1}
                                >
                                  <ListItem
                                    button
                                    component={RouterLink}
                                    to={`${baseUrl}${routes[STANDINGS].path}`}
                                    className={classes.listItem}
                                  >
                                    <Box m={2} display="flex">
                                      <ListItemIcon>
                                        {routes[STANDINGS].icon}
                                      </ListItemIcon>
                                      <ListItemText primary={routes[STANDINGS].name} classes={{primary: classes.listItemText}} />
                                    </Box>
                                  </ListItem>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {!isScoringComplete && (
                            <Typography variant="h5" gutterBottom>
                              Stay tuned for final scores!
                            </Typography>
                          )}
                        </Hidden>
                        <Hidden mdUp>
                          <Typography variant="h5" gutterBottom>
                            Thanks for joining us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                          </Typography>
                          {isScoringComplete && (
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box
                                  borderRadius={8}
                                  border={1}
                                >
                                  <ListItem
                                    button
                                    component={RouterLink}
                                    to={`${baseUrl}${routes[MY_ENTRY].path}`}
                                    className={classes.listItem}
                                  >
                                    <Box m={2} display="flex">
                                      <ListItemIcon>
                                        {routes[MY_ENTRY].icon}
                                      </ListItemIcon>
                                      <ListItemText primary={routes[MY_ENTRY].name} classes={{primary: classes.listItemText}} />
                                    </Box>
                                  </ListItem>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  borderRadius={8}
                                  border={1}
                                >
                                  <ListItem
                                    button
                                    component={RouterLink}
                                    to={`${baseUrl}${routes[STANDINGS].path}`}
                                    className={classes.listItem}
                                  >
                                    <Box m={2} display="flex">
                                      <ListItemIcon>
                                        {routes[STANDINGS].icon}
                                      </ListItemIcon>
                                      <ListItemText primary={routes[STANDINGS].name} classes={{primary: classes.listItemText}} />
                                    </Box>
                                  </ListItem>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {!isScoringComplete && (
                            <Typography variant="body1" gutterBottom>
                              Stay tuned for final scores!
                            </Typography>
                          )}
                        </Hidden>
                      </>
                    ) : (
                      <>
                        <Typography variant="h4">
                          Join us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                        </Typography>
                        {!isRankingsLocked && !isSeasonPredictionsLocked && (
                          <Box py={2}>
                            <Typography variant="h5" gutterBottom>
                              Complete your entry before you watch <strong>Episode 2</strong>, which airs on <strong>{secondEpisodeAirDateEnglish}</strong>.
                            </Typography>
                          </Box>
                        )}
                        <Typography variant="h5" gutterBottom>
                          Here's how to play along:
                        </Typography>
                        <Timeline align="left">
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Rank your favorite contestants
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  Each week, you'll get points if they're still in the show!
                                </Typography>
                                {user && !isRankingsLocked && (
                                  <Alert
                                    severity={rankingsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={rankingsComplete ? "inherit" : "primary"}
                                        variant={rankingsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[RANKS].path}`}
                                      >
                                        View Rankings 
                                      </Button>
                                    }
                                  >
                                    {rankingsComplete 
                                      ? <strong>You're finished with your contestant rankings!</strong>
                                      : <strong>You still need to finish ranking the contestants!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Answer some season-long trivia questions
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  You'll get points at the end of the season if you correctly predict events on the show!
                                </Typography>
                                {user && !isSeasonPredictionsLocked && (
                                  <Alert
                                    severity={seasonPredictionsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={seasonPredictionsComplete ? "inherit" : "primary"}
                                        variant={seasonPredictionsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[SEASON_PREDICTIONS].path}`}
                                      >
                                        View Predictions 
                                      </Button>
                                    }
                                  >
                                    {seasonPredictionsComplete 
                                      ? <strong>You're finished with your season predictions!</strong>
                                      : <strong>You still need to finish your season predictions!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          {user && (
                            <TimelineItem>
                              <TimelineOppositeContent style={{ flex: 0 }} />
                              <TimelineSeparator>
                                <TimelineDot 
                                  variant="outlined"
                                  color="primary"
                                />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Box
                                  mt={-1}
                                  mb={5}
                                >
                                  <Typography variant="h5" gutterBottom>
                                    Fill out your profile
                                  </Typography>
                                  <Typography variant="body1" paragraph>
                                    You can set a name and profile picture that will show in the standings!
                                  </Typography>
                                  <Alert
                                    severity={isUserComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={isUserComplete ? "inherit" : "primary"}
                                        variant={isUserComplete ? "outlined" : "contained"}
                                        disableElevation
                                        onClick={onSettingsClick}
                                      >
                                        Edit Profile 
                                      </Button>
                                    }
                                  >
                                    {isUserComplete 
                                      ? <strong>Your profile is all set up!</strong>
                                      : <strong>You still need to set up your profile!</strong>
                                    }
                                  </Alert>
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          )}
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              {!user && (
                                <TimelineConnector />
                              )}
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Follow along week to week
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  Each week, you can earn additional points for guessing what happens in each episode!
                                </Typography>
                                {user && !isWeeklyPredictionsLocked && (
                                  <Alert
                                    severity={weeklyPredictionsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={weeklyPredictionsComplete ? "inherit" : "primary"}
                                        variant={weeklyPredictionsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[WEEKLY_PREDICTIONS].path}`}
                                      >
                                        View Weekly Predictions
                                      </Button>
                                    }
                                  >
                                    {weeklyPredictionsComplete 
                                      ? <strong>You're finished with your weekly predictions for week {currentWeeklyPredictionsWeek}!</strong>
                                      : <strong>Make sure you fill out your weekly predictions!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          {!user && (
                            <TimelineItem>
                              <TimelineOppositeContent style={{ flex: 0 }} />
                              <TimelineSeparator>
                                <TimelineDot 
                                  variant="outlined"
                                  color="primary"
                                />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Box
                                  mt={-1}
                                  mb={5}
                                >
                                  <ButtonGroup
                                    color="primary"
                                    variant="contained"
                                  >
                                    <Button onClick={onSignUpClick}>Sign up</Button>
                                    <Button onClick={onSignInClick}>Sign in</Button>
                                  </ButtonGroup>
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          )}
                        </Timeline>
                      </>
                    )}

                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.grid}
          >
            <Grid item xs={10}>
              <Box style={{
                height: imageHeight,
              }}>
                <Image
                  src={`/images/${imageUrl}`}
                  cover
                  disableTransition
                  imageStyle={{
                    height: imageHeight,
                  }}
                  iconContainerStyle={{
                    height: imageHeight,
                  }}
                  style={{
                    paddingTop: 0,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Paper>
                <Box p={3}>
                <Grid
                  container
                  spacing={7}
                  className={classes.grid}
                >
                  <Grid item xs={12}>
                    {user && !isWeeklyPredictionsLocked && (
                      <Box mb={8}>
                        <Typography variant="h3" gutterBottom>
                          Week {currentWeeklyPredictionsWeek} predictions are up!
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                          Fill them out before you watch <strong>Episode {currentWeeklyPredictionsWeek}</strong>, which airs on <strong>{nextEpisodeAirDateEnglish}</strong>:
                        </Typography>
                        <Box py={3}>
                          <Countdown
                            endTime={nextEpisodeAirDate}
                          />
                        </Box>
                        <Alert
                          severity={weeklyPredictionsComplete ? "success" : "warning"}
                          action={
                            <Button
                              size="small"
                              color={weeklyPredictionsComplete ? "inherit" : "primary"}
                              variant={weeklyPredictionsComplete ? "outlined" : "contained"}
                              disableElevation
                              component={RouterLink}
                              to={`${baseUrl}${routes[WEEKLY_PREDICTIONS].path}`}
                            >
                              View Weekly Predictions
                            </Button>
                          }
                        >
                          {weeklyPredictionsComplete 
                            ? <strong>You're finished with your weekly predictions for week {currentWeeklyPredictionsWeek}!</strong>
                            : <strong>Make sure you fill out your weekly predictions!</strong>
                          }
                        </Alert>
                      </Box>
                    )}
                    {isSeasonOver ? (
                      <>
                        <Hidden smDown>
                          <Typography variant="h3" gutterBottom>
                            Thanks for joining us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box
                                borderRadius={8}
                                border={1}
                              >
                                <ListItem
                                  button
                                  component={RouterLink}
                                  to={`${baseUrl}${routes[MY_ENTRY].path}`}
                                  className={classes.listItem}
                                >
                                  <Box m={2} display="flex">
                                    <ListItemIcon>
                                      {routes[MY_ENTRY].icon}
                                    </ListItemIcon>
                                    <ListItemText primary={routes[MY_ENTRY].name} classes={{primary: classes.listItemText}} />
                                  </Box>
                                </ListItem>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                borderRadius={8}
                                border={1}
                              >
                                <ListItem
                                  button
                                  component={RouterLink}
                                  to={`${baseUrl}${routes[STANDINGS].path}`}
                                  className={classes.listItem}
                                >
                                  <Box m={2} display="flex">
                                    <ListItemIcon>
                                      {routes[STANDINGS].icon}
                                    </ListItemIcon>
                                    <ListItemText primary={routes[STANDINGS].name} classes={{primary: classes.listItemText}} />
                                  </Box>
                                </ListItem>
                              </Box>
                            </Grid>
                          </Grid>
                        </Hidden>
                        <Hidden mdUp>
                          <Typography variant="h5" gutterBottom>
                            Thanks for joining us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box
                                borderRadius={8}
                                border={1}
                              >
                                <ListItem
                                  button
                                  component={RouterLink}
                                  to={`${baseUrl}${routes[MY_ENTRY].path}`}
                                  className={classes.listItem}
                                >
                                  <Box m={2} display="flex">
                                    <ListItemIcon>
                                      {routes[MY_ENTRY].icon}
                                    </ListItemIcon>
                                    <ListItemText primary={routes[MY_ENTRY].name} classes={{primary: classes.listItemText}} />
                                  </Box>
                                </ListItem>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                borderRadius={8}
                                border={1}
                              >
                                <ListItem
                                  button
                                  component={RouterLink}
                                  to={`${baseUrl}${routes[STANDINGS].path}`}
                                  className={classes.listItem}
                                >
                                  <Box m={2} display="flex">
                                    <ListItemIcon>
                                      {routes[STANDINGS].icon}
                                    </ListItemIcon>
                                    <ListItemText primary={routes[STANDINGS].name} classes={{primary: classes.listItemText}} />
                                  </Box>
                                </ListItem>
                              </Box>
                            </Grid>
                          </Grid>
                        </Hidden>
                      </>
                    ) : (
                      <>
                        <Typography variant="h3" gutterBottom>
                          Join us for {name}'s {isBachelorette ? "Bachelorette" : "Bachelor"} season!
                        </Typography>
                          {!isRankingsLocked && !isSeasonPredictionsLocked && (
                            <Box py={3}>
                              <Typography variant="h5" gutterBottom>
                                Complete your entry before you watch <strong>Episode 2</strong>, which airs on <strong>{secondEpisodeAirDateEnglish}</strong>:
                              </Typography>
                              <Box pt={1}>
                                <Countdown
                                  endTime={secondEpisodeAirDate}
                                />
                              </Box>
                            </Box>
                          )}
                        <Typography variant="h5" gutterBottom>
                          Here's how to play along:
                        </Typography>
                        <Timeline align="left">
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Rank your favorite contestants
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  Each week, you'll get points if they're still in the show!
                                </Typography>
                                {user && !isRankingsLocked && (
                                  <Alert
                                    severity={rankingsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={rankingsComplete ? "inherit" : "primary"}
                                        variant={rankingsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[RANKS].path}`}
                                      >
                                        View Rankings 
                                      </Button>
                                    }
                                  >
                                    {rankingsComplete 
                                      ? <strong>You're finished with your contestant rankings!</strong>
                                      : <strong>You still need to finish ranking the contestants!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Answer some season-long trivia questions
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  You'll get points at the end of the season if you correctly predict events on the show!
                                </Typography>
                                {user && !isSeasonPredictionsLocked && (
                                  <Alert
                                    severity={seasonPredictionsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={seasonPredictionsComplete ? "inherit" : "primary"}
                                        variant={seasonPredictionsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[SEASON_PREDICTIONS].path}`}
                                      >
                                        View Predictions 
                                      </Button>
                                    }
                                  >
                                    {seasonPredictionsComplete 
                                      ? <strong>You're finished with your season predictions!</strong>
                                      : <strong>You still need to finish your season predictions!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          {user && (
                            <TimelineItem>
                              <TimelineOppositeContent style={{ flex: 0 }} />
                              <TimelineSeparator>
                                <TimelineDot 
                                  variant="outlined"
                                  color="primary"
                                />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Box
                                  mt={-1}
                                  mb={5}
                                >
                                  <Typography variant="h5" gutterBottom>
                                    Fill out your profile
                                  </Typography>
                                  <Typography variant="body1" paragraph>
                                    You can set a name and profile picture that will show in the standings!
                                  </Typography>
                                  <Alert
                                    severity={isUserComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={isUserComplete ? "inherit" : "primary"}
                                        variant={isUserComplete ? "outlined" : "contained"}
                                        disableElevation
                                        onClick={onSettingsClick}
                                      >
                                        Edit Profile 
                                      </Button>
                                    }
                                  >
                                    {isUserComplete 
                                      ? <strong>Your profile is all set up!</strong>
                                      : <strong>You still need to set up your profile!</strong>
                                    }
                                  </Alert>
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          )}
                          <TimelineItem>
                            <TimelineOppositeContent style={{ flex: 0 }} />
                            <TimelineSeparator>
                              <TimelineDot 
                                variant="outlined"
                                color="primary"
                              />
                              {!user && (
                                <TimelineConnector />
                              )}
                            </TimelineSeparator>
                            <TimelineContent>
                              <Box
                                mt={-1}
                                mb={5}
                              >
                                <Typography variant="h5" gutterBottom>
                                  Follow along week to week
                                </Typography>
                                <Typography variant="body1" paragraph>
                                  Each week, you can earn additional points for guessing what happens in each episode!
                                </Typography>
                                {user && !isWeeklyPredictionsLocked && (
                                  <Alert
                                    severity={weeklyPredictionsComplete ? "success" : "warning"}
                                    action={
                                      <Button
                                        size="small"
                                        color={weeklyPredictionsComplete ? "inherit" : "primary"}
                                        variant={weeklyPredictionsComplete ? "outlined" : "contained"}
                                        disableElevation
                                        component={RouterLink}
                                        to={`${baseUrl}${routes[WEEKLY_PREDICTIONS].path}`}
                                      >
                                        View Weekly Predictions
                                      </Button>
                                    }
                                  >
                                    {weeklyPredictionsComplete 
                                      ? <strong>You're finished with your weekly predictions for week {currentWeeklyPredictionsWeek}!</strong>
                                      : <strong>Make sure you fill out your weekly predictions!</strong>
                                    }
                                  </Alert>
                                )}
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                          {!user && (
                            <TimelineItem>
                              <TimelineOppositeContent style={{ flex: 0 }} />
                              <TimelineSeparator>
                                <TimelineDot 
                                  variant="outlined"
                                  color="primary"
                                />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Box
                                  mt={-1}
                                  mb={5}
                                >
                                  <ButtonGroup
                                    color="primary"
                                    variant="contained"
                                  >
                                    <Button onClick={onSignUpClick}>Sign up</Button>
                                    <Button onClick={onSignInClick}>Sign in</Button>
                                  </ButtonGroup>
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          )}
                        </Timeline>
                      </>
                    )}

                  </Grid>
                </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
    seasons: state.firestore.data.seasons,
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    ...(props.user ? [
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    }] : [])
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(HomePage);
