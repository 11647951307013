import React, { Component } from "react";

import {
  FormControl,
  Box,
  Select,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

const styles = (theme) => ({
  select: {
    "& .MuiSelect-selectMenu": {
      paddingTop: theme.spacing(1.5),
    }
  },
  contextLabel: {
    color: fade("#000000", 0.54),
  },
});

class ContestantSelector extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      readonly,
      disabled,
      label,
      value,
      onChange,
      contestantIdOptions,
      contestantData,
      allowNone,
    } = this.props;

    return (
      <FormControl
        fullWidth
        variant="filled"
        size="small"
        disabled={disabled}
      >
        <Select
          className={classes.select}
          label={label}
          labelId="contestant-select-label"
          id="contestant-select"
          value={readonly ? "" : value}
          displayEmpty={readonly}
          onChange={onChange}
          renderValue={selected => {
            if (selected === -1) {
              return (
                <Typography>
                  None of the above
                </Typography>
              )
            }
            if (selected !== "") {
              const contestant = contestantData.byId[selected]
              return (
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Box pr={2}>
                    <Avatar
                      src={`/images/${contestant.fullImageName}`}
                    />
                  </Box>
                  <ListItemText primary={contestant.name} />
                </Box>
              );
            } else {
              return (
                <Typography
                  className={classes.contextLabel}
                >
                  {label}
                </Typography>
              )
            }
          }}
        >
          {label && (
            <MenuItem disabled value="">
              {label}
            </MenuItem>
          )}
          {contestantIdOptions
            .map((contestantId) =>
              contestantData.byId[contestantId])
            .map((contestant) => (
              <MenuItem
                key={contestant.id}
                value={contestant.id}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={contestant.name} />
              </MenuItem>
            ))
          }
          {allowNone && (
            <MenuItem value={-1}>
              None of the above
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(ContestantSelector);
