import React, { Component } from "react";

import {
  Box,
  Avatar,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  contestantWon: {
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
  },
  eliminated: {
    backgroundColor: fade(appearance.colors.red.import[500], 0.2),
  },
});

class WinnerCell extends Component {
  constructor(props) {
    super(props);
  }

  getWinnerBackgroundClassName = (isWinner, isEliminated) => {
    const { classes } = this.props;

    if (isWinner) {
      return classes.contestantWon;
    } else if (isEliminated) {
      return classes.eliminated;
    }

    return "";
  };

  render() {
    // Styling
    const { classes } = this.props;

    const {
      winner,
    } = this.props;

    const {
      isWinnerEliminated,
    } = winner;

    return (
      <Box
      >
        {winner.name &&
          <Box
            display="flex"
            alignItems="center"
          >
            <Box pr={1}>
              <Avatar 
                src={(`/images/${winner.fullImageName}`)}
              />
            </Box>
            {isWinnerEliminated && (
              <s>{winner.name}</s>
            )}

            {!isWinnerEliminated && (
              winner.name
            )}
          </Box>
        }
        {!winner.name &&
          <Box
            display="flex"
            alignItems="center"
          >
            <Box pr={1}>
              <Avatar />
            </Box>
            No winner picked! 
          </Box>
        }
      </Box>
    )
  }
}

export default withStyles(styles)(WinnerCell);
