import React, { Component } from "react";

import { ListItem, ListItemText, ListItemAvatar, Avatar, Paper, Box, Grid, SvgIcon, Chip, Typography, Tooltip, Hidden, } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import { Draggable } from "react-beautiful-dnd";

import appearance from "../../services/appearance";

import { ReactComponent as RoseIcon } from "../../assets/icons/rose-full.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";

import { storage } from "../../firebase";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  avatarEliminated: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    filter: "grayscale(1)",
  },
  draggableContestant: {
    borderRadius: "16px",
  },
  defaultContestant: {
    borderRadius: "16px",
    border: "3px solid rgba(0, 0, 0, 0.12)",
  },
  scoredPoints: {
    borderRadius: "16px",
    border: "3px solid rgb(61 171 65 / 40%)",
  },
  noPoints: {
    borderRadius: "16px",
    border: `3px solid ${appearance.colors.amber.import[200]}`,
  },
  belowThreshold: {
    borderRadius: "16px",
    border: `3px solid ${fade(appearance.colors.gray.import[400], 0.5)}`,
  },
  eliminated: {
    borderRadius: "16px",
    border: `3px solid ${fade(appearance.colors.gray.import[400], 0.5)}`,
  },
  newlyEliminated: {
    borderRadius: "16px",
    border: `3px solid ${fade(appearance.colors.red.import[500], 0.5)}`,
  },
  eliminatedChip: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  newlyEliminatedChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: fade(appearance.colors.red.import[500], 0.5),
    fontWeight: "bold",
  },
  noPointsChip: {
    marginLeft: theme.spacing(0.5),
    backgroundColor: appearance.colors.amber.import[200],
    fontWeight: "bold",
  },
  scoreChip: {
    marginLeft: theme.spacing(0.5),
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
    fontWeight: "bold",
  },
  roseIcon: {
    marginLeft: theme.spacing(0.75),
    height: '24px',
    width: '24px',
  },
  xIcon: {
    marginLeft: theme.spacing(1.25),
    height: '14px',
    width: '14px',
  },
});

class ContestantCard extends Component {
  constructor(props) {
    super(props);
  }

  getBackgroundClassName = (rank, scoredPoints, belowThreshold, eliminated, newlyEliminated, receivedRose) => {
    const { classes } = this.props;

    if (receivedRose && !scoredPoints) {
      return classes.noPoints;
    } else if (scoredPoints) {
      return classes.scoredPoints;
    } else if (belowThreshold) {
      return classes.belowThreshold;
    } else if (newlyEliminated) {
      return classes.newlyEliminated;
    } else if (eliminated) {
      return classes.eliminated;
    }

    return classes.defaultContestant;
  };
  
  render() {
    // Styling
    const { classes } = this.props;

    // Passed callback
    const { onClick } = this.props;

    const {
      contestant,
      index,
      rank,
      pointsPerRose,
      scoredPoints,
      receivedRose,
      belowThreshold,
      eliminated,
      newlyEliminated,
      isBachelorette,
      readOnly,
      button,
      draggable,
      gutterBottom,
    } = this.props;

    if (draggable) {
      return (
        <Draggable draggableId={contestant.id.toString()} index={index}>
          {(provided, snapshot) => (
            <Box
              pb={2}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Paper
                elevation={3}
                className={classes.draggableContestant}
              >
                <ListItem
                  button
                  disableRipple={snapshot.isDragging}
                  onClick={() => onClick(contestant.id)}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={`/images/${contestant.fullImageName}`}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                  <Hidden smUp>
                    <ListItemText
                      primary={contestant.name}
                    />
                  </Hidden>
                  <Hidden xsDown>
                    <ListItemText
                      primary={contestant.name + ", " + contestant.age}
                      secondary={contestant.occupation}
                    />
                  </Hidden>
                </ListItem>
              </Paper>
              {provided.placeholder}
            </Box>
          )}
        </Draggable>
      );
    }

    return (
      <Box
        index={index}
        pb={gutterBottom ? 2 : 0}
      >
        <Paper
          elevation={3}
          variant={readOnly ? "outlined" : "elevation"}
          className={this.getBackgroundClassName(rank, scoredPoints, belowThreshold, eliminated, newlyEliminated, receivedRose)}
        >
          {button && (
            <ListItem
              button
              onClick={() => onClick(contestant.id)}
            >
              <ListItemAvatar>
                {!newlyEliminated && eliminated && (
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                    className={classes.avatarEliminated}
                  />
                )}
                {!eliminated && (
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                    className={classes.avatar}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={readOnly ? contestant.name : contestant.name + ", " + contestant.age}
                secondary={readOnly ? "" : contestant.occupation}
              />
            </ListItem>
          )}

          {!button && (
            <ListItem>
              <ListItemAvatar>
                {!newlyEliminated && eliminated && (
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                    className={classes.avatarEliminated}
                  />
                )}
                {!eliminated && (
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                    className={classes.avatar}
                  />
                )}
                {newlyEliminated && (
                  <Avatar
                    src={`/images/${contestant.fullImageName}`}
                    className={classes.avatar}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={readOnly ? contestant.name + ", " + contestant.age: contestant.name + ", " + contestant.age}
                secondary={readOnly ? "" : contestant.occupation}
              />
              {scoredPoints && (
                <Tooltip 
                  title={contestant.name + " got a rose this week!"}
                >
                  <Chip
                    icon={receivedRose && (
                      <SvgIcon component={RoseIcon} viewBox="0 0 512 512" htmlColor="white" className={classes.roseIcon} />
                    )}
                    label={`+${pointsPerRose}`}
                    className={classes.scoreChip}
                  />
                </Tooltip>
              )}
              {receivedRose && !scoredPoints && (
                <Tooltip 
                  title={`${contestant.name} got a rose, but you ranked ${isBachelorette ? "him" : "her"} too low!`}
                >
                  <Chip
                    icon={receivedRose && (
                      <SvgIcon component={RoseIcon} viewBox="0 0 512 512" htmlColor="white" className={classes.roseIcon} />
                    )}
                    label="No Points"
                    className={classes.noPointsChip}
                  />
                </Tooltip>
              )}
              {newlyEliminated && (
                <Tooltip 
                  title={contestant.name + " went home this week!"}
                >
                  <Chip
                    icon={<SvgIcon component={XIcon} viewBox="0 0 8 8" className={classes.xIcon} />}
                    label="Eliminated!"
                    className={classes.newlyEliminatedChip}
                  />
                </Tooltip>
              )}
              {!newlyEliminated && eliminated && contestant.weekEliminated >= 1 && (
                <Chip
                  label={`Eliminated week ${contestant.weekEliminated}`}
                  className={classes.eliminatedChip}
                />
              )}
            </ListItem>
          )}
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ContestantCard);
